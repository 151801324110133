import React, { useEffect, useState } from "react";
import "./Progess.css";

function Progressbar() {
  const [scrollTop, setScrollTop] = useState(0);

  const onscroll = () => {
    const winScroll = document.documentElement.scrollTop;
    const heigth =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = (winScroll / heigth) * 100;
    setScrollTop(scrolled);
  };

  useEffect(() => {
    window.addEventListener("scroll", onscroll);
    return () => window.removeEventListener("scroll", onscroll);
  });

  return (
    <>
      <div className="bar" style={{ width: `${scrollTop}%` }}></div>
    </>
  );
}

export default Progressbar;
