/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-lone-blocks */
import React from "react";
import "./Content3.css";
import Simple from "../Landpage/hao-wang-pVq6YhmDPtk-unsplash.jpg";
import Fade from "react-reveal/Fade";

function Content3() {
  return (
    <>
      <div className="content3" id="About">
        <div className="content3holder">
          <Fade left>
            <h1>service</h1>
          </Fade>
          <p>
            <Fade left>
              DC helps customers modernize technology and evolving various
              software types, reimagine processes and transform experiences so
              they stay ahead in a fast-changing world.
            </Fade>
          </p>
        </div>
        <div className="content3holder">
          <Fade right>
            <img className="content3img" src={Simple} />
          </Fade>
        </div>
      </div>
    </>
  );
}

export default Content3;
