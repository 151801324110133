import React from "react";
import "./Companyabout.css";
import Simple from "../Landpage/hao-wang-pVq6YhmDPtk-unsplash.jpg";
import Zoom from "react-reveal/Zoom";

function Companyabout() {
  return (
    <>
      <div className="companylogo">
        <div className="companylogoholder">
          <div className="logo">
            <Zoom bottom>
              <img className="companylogoimg" src={Simple} />
            </Zoom>
          </div>
        </div>
      </div>
    </>
  );
}

export default Companyabout;
