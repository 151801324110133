import React from "react";
import "./Landpage.css";
import Nav from "../Navbar/Nav";

function Landpage() {
  return (
    <>
      <div className="homepage">
        <div className="glasspage">
          <Nav />
          <div className="homecontent">
            <div className="maincontent maintitle">
              <h1>We build digital pendentives for client dome</h1>
            </div>
            <div className="subcontent">
              <p>
              Let's uncover intuitive business ideas
              </p>
            </div>
          </div>
        </div>
        <footer className="gradient"></footer>
      </div>
    </>
  );
}

export default Landpage;
