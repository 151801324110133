import React, { useRef } from "react";
import "./Footer.css";
import emailjs from "@emailjs/browser";
import Bounce from "react-reveal/Bounce";

import { useAlert } from "react-alert";

function Footer() {
  const form = useRef();
  const alert = useAlert();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_2cm3chn",
        "template_jbffvsa",
        form.current,
        "gofrsKyiIqPU8ji7t"
      )
      .then(
        (result) => {
          alert.success("Sucessfully Send");
          e.target.reset();
        },
        (error) => {
          alert.error("Oops!,Something is Wrong");
        }
      );
  };
  return (
    <>
      <div className="footerBot">
        <div className="botsvg">
          <svg
            className="footersvg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 320"
          >
            <path
              fill="#f3f4f5"
              d="M0,288L60,272C120,256,240,224,360,213.3C480,203,600,213,720,213.3C840,213,960,203,1080,197.3C1200,192,1320,192,1380,192L1440,192L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"
            ></path>
          </svg>
        </div>

        <div className="footerhold" id="Contact_As">
          <div className="con">
            <Bounce top>
              <h1>Contact As</h1>
            </Bounce>
          </div>

          <form ref={form} onSubmit={sendEmail}>
            <div className="formholder">
              <Bounce top>
                <input
                  className="emailtype"
                  type="email"
                  pattern=".+@gmail.com"
                  required
                  placeholder="Email"
                  name="email"
                  autoComplete="off"
                />
              </Bounce>
              <Bounce top>
                <input
                  className="phonetype"
                  type="tel"
                  pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                  required
                  placeholder="PhoneNumber"
                  name="user_phone"
                  autoComplete="off"
                />
              </Bounce>
              <Bounce top>
                <textarea
                  className="msgtype"
                  cols="30"
                  rows="8"
                  placeholder="About Project"
                  name="user_msg"
                  autoComplete="off"
                  required
                />
              </Bounce>
              <Bounce top>
                <input className="bthsumbit" type="submit" value="Send Mail" />
              </Bounce>
            </div>
          </form>
          <Bounce top>
            <p className="copy">© CopyRight Dio Computo 2022</p>
          </Bounce>
        </div>
      </div>
    </>
  );
}

export default Footer;
