import React, { useState } from "react";
import "./Nav.css";
import Fade from "react-reveal/Fade";

function Nav() {
  const [moblie, setmoblie] = useState(false);
  return (
    <>
      <Fade bottom>
        <nav className="companynav">
          <div className="navhold">
            <div className="companyname">
              <h1>Dio computo</h1>
            </div>
            <div
              className={moblie ? "companyother-moblie" : "companyother"}
              // onClick={() => setmoblie(false)}
            >
              <li className="otherli">
                <a className="otherurl" href="#service">
                  Service
                </a>
              </li>
              <li className="otherli">
                <a className="otherurl" href="#Technologies">
                  Technologies
                </a>
              </li>
              <li className="otherli">
                <a className="otherurl" href="#About">
                  About
                </a>
              </li>
              <li className="otherli">
                <a className="otherurl" href="#Contact_As">
                  Contact As
                </a>
              </li>
            </div>
            <button className="navbtn" onClick={() => setmoblie(!moblie)}>
              {moblie ? (
                <i className="fas fa-times xbar"></i>
              ) : (
                <i className="fas fa-bars "></i>
              )}
            </button>
          </div>
        </nav>
      </Fade>
    </>
  );
}

export default Nav;
