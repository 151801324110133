/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import "./Content2.css";
import Simple from "../Landpage/Creative.png";
import Fade from "react-reveal/Fade";

export default function Content2() {
  return (
    <>
      <div className="content2" id="Technologies">
        <div className="content2holder">
          <Fade left>
            <img className="content2img" src={Simple} />
          </Fade>
        </div>
        <div className="content2holder">
          <Fade right>
            <h1>Technologies</h1>
          </Fade>
          <Fade right>
            <p>
            To satisfy customers, we pay more attention while selecting technology includes cost and time, productivity, main requirement, customizability and adaptability, etc. Benefits of technology includes saves time, cost efficiency, ease of access to information, better learning techniques.
            </p>
          </Fade>
        </div>
      </div>
    </>
  );
}
