import Companyabout from "./Ui/Compangcontent.js/Companyabout";
import Footer from "./Ui/Footer/Footer";
import Landpage from "./Ui/Landpage/Landpage.js";
import Content1 from "./Ui/MainContent1/Content1";
import Content2 from "./Ui/Maincontent2/Content2";
import Content3 from "./Ui/MainContent3/Content3";
import Content4 from "./Ui/Maincontent4/Content4";
import Progressbar from "./Ui/Progess/Progess";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";

const options = {
  position: positions.TOP_CENTER,
  timeout: 3000,
  transition: transitions.SCALE,
  offset: "35px",
};

function App() {
  return (
    <div className="App">
      <Progressbar />
      <Landpage />
      <Content1 />
      <Content2 />
      <Companyabout />
      <Content3 />
      <Content4 />
      <AlertProvider template={AlertTemplate} {...options}>
        <Footer />
      </AlertProvider>
    </div>
  );
}

export default App;
// let ProgessWidth =
//   (scrolldistance / props.Pagehieght[0].getBoundingClientRect().height) *
//   100;
// console.log(ProgessWidth);
// let ProgessWidth =
//   (scrolldistance /
//     (props.Pagehieght[0].getBoundingClientRect().height -
//       document.documentElement.clientHeight)) *
//   100;
// let value = Math.floor(ProgessWidth);
// Progessbar[0].style.width = value + "%";
