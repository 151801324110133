/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from "react";
import "./Content1.css";
import Fade from "react-reveal/Fade";
import AOS from "aos";
import "aos/dist/aos.css";

function Test() {
  const [Line, setLine] = useState(
    "Nothing else looks like a Prezi presentation. And no other presentatio software is as engaging, persuasive, and memorable."
  );
  function simple() {
    setLine(
      "A little thought and a new strategy will give the answer in a very easy way. You share your intuition, we inject our technology. We will explore your intuition and help you do business magnificently."
    );
  }
  function simple2() {
    setLine(
      "Innovation-led application services. That means, our application services help you reimagine your application landscape with speed and agility, while continuously innovating to realise business value. changing complex application landscapes with a mix of legacy systems to digital and SaaS-based."
    );
  }
  function simple3() {
    setLine(
      "'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident,some"
    );
  }
  function simple4() {
    setLine(
      "d words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anyt"
    );
  }
  useEffect(() => {
    AOS.init({ duration: 1500 });
  });
  return (
    <>
      <div className="content1" id="service">
        <div className="content1holder">
          <Fade left>
            <h1>Services</h1>
          </Fade>

          <Fade left>
            <p>
              DC helps customers modernize technology and evolving various
              software types, reimagine processes and transform experiences so
              they stay ahead in a fast-changing world.
            </p>
          </Fade>

          <div className="servicelist">
            <Fade left>
              <li onClick={simple} className="servicelistitem">
                Let's create the product
              </li>
              <li onClick={simple2} className="servicelistitem">
                Application Services
              </li>
              {/* <li onClick={simple3} className="servicelistitem">
                Sales
              </li>
              <li onClick={simple4} className="servicelistitem">
                Education
              </li> */}
            </Fade>
          </div>
        </div>
        <div className="content1holder Services_bg">
          <p>
            <Fade right>{Line}</Fade>
          </p>
        </div>
      </div>
    </>
  );
}

export default Test;
{
  /* <img className="content1paraimg" src={Dio} /> */
}

{
  /* <div data-aos="zoom-in" className="live"></div> */
}
