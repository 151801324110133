/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import "./Content4.css";
import Dio from "./dio.png";

import Roll from "react-reveal/Roll";

import Slide from "react-reveal/Slide";
import Bounce from "react-reveal/Bounce";

export default function Content4() {
  return (
    <>
      <div className="content4">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#f3f4f5"
            d="M0,64L80,106.7C160,149,320,235,480,250.7C640,267,800,213,960,192C1120,171,1280,181,1360,186.7L1440,192L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
          ></path>
        </svg>
        <div className="curvejoin">
          <div className="content4notes">
            <Roll top>
              <img className="diologo" src={Dio} />
            </Roll>
          </div>
          <div className="content4notes">
            <div className="content4subnotes">
              <Bounce top>
                <div className="card">
                  <div className="circle">
                    <h1>Dio Computo</h1>
                  </div>

                  <div className="content">
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                    </p>
                  </div>
                  <img className="diologo2" src={Dio} />
                </div>
              </Bounce>

              <h1 className="contentp2">
                <Slide top cascade>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                </Slide>
              </h1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
//  <div className="curvejoin">
//    <div className="content4notes">
//      <div className="diologoholder">
//        <Roll top>
//          <img className="diologo" src={Dio} />
//        </Roll>
//      </div>
//    </div>
//    <div className="content4notes">
//      <div className="content4subnotes">
//        <Bounce top>
//          <div className="card">
//            <div className="circle">
//              <h1>Dio Computo</h1>
//            </div>
//            <div className="content">
//              <p>
//                Lorem Ipsum is simply dummy text of the printing and typesetting
//                industry. Lorem Ipsum has been the industry's
//              </p>
//            </div>
//            <img className="diologo2" src={Dio} />
//          </div>
//        </Bounce>
//        <h1>
//          <Slide top cascade>
//            Lorem Ipsum is simply dummy text of the printing and typesetting
//            industry. Lorem Ipsum has been the industry's
//          </Slide>
//        </h1>
//      </div>
//    </div>
//  </div>;
//new
// <div className="curvejoin">
//   <div className="content4holder">
//     <img className="diologo" src={Dio} />
//   </div>
//   <div className="content4holder">
//     <Bounce top>
//       <div className="card">
//         <div className="circle">
//           <h1>Dio Computo</h1>
//         </div>

//         <div className="content">
//           <p>
//             Lorem Ipsum is simply dummy text of the printing and typesetting
//             // industry. Lorem Ipsum has been the industry's
//           </p>
//         </div>
//         <img className="diologo2" src={Dio} />
//       </div>
//     </Bounce>

//     <h1>
//       <Slide top cascade>
//         Lorem Ipsum is simply dummy text of the printing and typesetting
//         industry. Lorem Ipsum has been the industry's
//       </Slide>
//     </h1>
//   </div>
// </div>;
